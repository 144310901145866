<template>
    <div id="annexData" v-loading="loading">
        <div class="plannedSpeed">
            <el-progress
                :percentage="progressPercent"
                status="success"
                :show-text="false"
                v-if="isProgress"
            ></el-progress>
        </div>
        <el-row>
            <el-col :span="20">
                <nav>
                    <div class="Route">
                        <a @click="backTop()">全部资料</a>
                        <template v-for="(item, index) in fileHierarchy">
                            <span
                                :key="index"
                                @click="returnDirectory(item.id, index, item)"
                            >
                                <i class="el-icon-arrow-right"></i>
                                {{ item.data }}
                            </span>
                        </template>
                    </div>
                </nav>
            </el-col>
            <el-col :span="4" class="fileBtn" v-if="!formData.type">
                <button
                    title="上传"
                    v-if="isRead !== true"
                    @click.stop
                >
                    <uploading
                        ref="uploading"
                        @uploadFirm="gitAnnexData"
                        :businessid="businessIId"
                        :bu-code="buCode"
                        :parentid="parentid"
                        class="uploadfile"
                        :curr-data="currData"
                    ></uploading>
                    <!-- <el-upload
                        class="uploadfile"
                        action=""
                        :before-upload="beforeUpload"
                        :http-request="uploadFileMethod"
                        :show-file-list="false"
                        multiple
                    >
                        <i class="iconfont iconshangchuan"></i>
                    </el-upload> -->
                </button>
                <button class="iconfont icontianjia" v-if="isRead !== true" @click="AddFile"></button>
                <!-- <button class="iconfont iconxiazai1"></button> -->
            </el-col>
        </el-row>
        <div id="Attachment" class="Annex">
            <div class="AnnexInfo" id="AnnexInfoContainer" options="Setting">
                <table>
                    <thead v-if="isAdd">
                        <tr class="tebleFome">
                            <th style="width: 5%;">
                                <svg class="iconwenjianjia1" aria-hidden="true">
                                    <use xlink:href="#iconwenjianjia1"></use>
                                </svg>
                            </th>
                            <td colspan="5">
                                <input
                                    type="text"
                                    v-model="newFile.group_name"
                                    class="form-control"
                                    placeholder="请输入文件夹名称"
                                >
                                <!-- <input
                                    type="text"
                                    v-model="newFile.FileDescription"
                                    class="form-control"
                                    placeholder="请输入描述"
                                > -->
                                <button class="el-icon-check" title="确认添加" @click="confirmAdd()"></button>
                                <button class="el-icon-close" title="取消添加" @click="cancelAdd()"></button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="fileData && fileData.length>0">
                            <tr v-for="(item, index) in fileData" :key="index" @click="seeFile(item)">
                                <th style="width: 5%;">
                                    <svg v-if="item.file_ext === ''" class="iconwenjianjia1" aria-hidden="true">
                                        <use xlink:href="#iconwenjianjia1"></use>
                                    </svg>
                                    <svg v-if="item.file_ext !== ''" class="iconwenjian" aria-hidden="true">
                                        <use xlink:href="#iconwenjian"></use>
                                    </svg>
                                </th>
                                <td style="width: 34%;">
                                    <span
                                        ref="content"
                                        class="fileName"
                                        :title="item.file_name"
                                        @blur="preservationName($event, index, item)"
                                    >{{ item.file_name }}</span>
                                </td>
                                <td style="width: 6%;" v-if="item.file_ext">
                                    {{ bytesToSize(item.file_size) }}
                                </td>
                                <td style="width: 22%;" class="btns">
                                    <button
                                        class="iconfont iconbianji"
                                        title="重命名"
                                        v-if="isRead !== true"
                                        @click.stop="editFileClick(index)"
                                    ></button>
                                    <button
                                        v-if="item.file_ext === '' && isRead !== true"
                                        title="上传"
                                        @click.stop="uploadClick(item)"
                                    >
                                        <!-- <el-upload
                                            class="uploadfile"
                                            action=""
                                            :before-upload="beforeUpload"
                                            :http-request="uploadFileMethod"
                                            :show-file-list="false"
                                            multiple
                                        >
                                            <i class="iconfont iconshangchuan"></i>
                                        </el-upload> -->
                                        <uploading
                                            @uploadFirm="gitAnnexData"
                                            :businessid="businessIId"
                                            :bu-code="buCode"
                                            :parentid="parentid"
                                            :curr-data="currData"
                                        ></uploading>
                                    </button>
                                    <button
                                        v-if="item.file_ext !== '' && isRead !== true"
                                        class="iconfont iconxiazai1"
                                        title="下载"
                                        @click.stop="download(item)"
                                    ></button>
                                    <button
                                        v-if="item.file_ext !== '' && isRead !== true && canPreview(item)"
                                        class="iconfont iconicon_yulan"
                                        title="预览"
                                        @click.stop="viewFileClick(item)"
                                    ></button>
                                    <button
                                        v-if="item.file_ext !== '' && isRead !== true && !formData.type"
                                        class="iconfont iconshanchu"
                                        title="删除文件"
                                        @click.stop="deleteFileClick(index, item)"
                                    ></button>
                                    <button
                                        v-if="item.file_ext === '' && isRead !== true && !formData.type"
                                        class="iconfont iconshanchu"
                                        title="删除文件夹"
                                        @click.stop="deleteFileClick(index, item)"
                                    ></button>
                                </td>
                                <td style="width: 21%;" :title="item.describe">
                                    {{ item.FileDescription }}
                                </td>
                                <td>{{ item.modified }}</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr class="noeAnnexData">
                                <td colspan="6">
                                    <p>
                                        暂无附件
                                    </p>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="dialog_box">
            <el-dialog
                title="预览"
                :visible.sync="dialogVisible"
                width="45%"
                :before-close="handleClose"
                append-to-body
            >
                <div>
                    <img class="img_url" :src="imgUrl" alt="">
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import '@/assets/css/annexStyle.styl';
import uploading from '@/components/uploading/index.vue';
export default {
    // 附件资料
    name: 'annex',
    components: { uploading },
    props: {
        formId: {
            type: [Array, Object, String, Number],
        },
        formData: {
            type: [Array, Object, String ],
        },
        isRead: {
            type: [Boolean],
        },
    },
    data() {
        return {
            dialogVisible: false,
            // 预览图片地址
            imgUrl: '',
            // 操作项
            currData: null,
            parentid: '',
            buCode: 'Flow',
            businessIId: '',
            // 附件面包屑导航数据
            fileHierarchy: [],
            // 附件数据
            fileData: [],
            // 当前附件ID
            fileID: '',
            // 预览附件数据
            picturePreview: [],
            // 新增文件夹绑定数据
            newFile: {
                group_name: '',
                FileDescription: '',
            },
            // 新增文件夹栏状态
            isAdd: false,
            // 当前父ID 上传附件使用，在文件夹上上传，和新增文件父id不一样
            currentFile: 0,
            // 上传状态
            loading: false,
            // 文件上传进度条状态
            isProgress: false,
            // 文件上传进度
            progressPercent: 0,
            // 支持预览的文件类型
            // eslint-disable-next-line max-len
            previewTypes: ['pdf', 'doc', 'docx', 'dot', 'wps', 'wpt', 'dotx', 'docm', 'dotm', 'rtf', 'et', 'xls', 'xlt', 'xlsx', 'xlsm', 'xltx', 'xltm', 'csv', 'ppt', 'pptx', 'pptm', 'ppsx', 'ppsm', 'pps', 'potx', 'potm', 'dpt', 'dps' ],
        };
    },
    watch: {
        formId(newVal) {
            this.fileID = newVal;
        },
    },
    created() {
        this.businessIId = this.formId;
        // 获取附件数据
        this.fileID = this.formId;
        if (this.fileID || this.fileID !== '') {
            this.gitAnnexData(this.formId);
        }

    },
    methods: {
        canPreview(data) {
            if (data.mime_type.includes('text/')
                || data.mime_type.includes('image/')
                || data.mime_type.includes('audio/')
                || data.mime_type.includes('video/')) {
                return true;
            }
            if (this.previewTypes.includes(data.file_ext)) {
                return true;
            }
        },
        // 弹窗取消
        handleClose() {
            this.dialogVisible = false;
        },
        // 文件大小转换
        bytesToSize(fileByte) {
            const fileSizeByte = fileByte;
            let fileSizeMsg = '';
            if (fileSizeByte < 1048576) {
                fileSizeMsg = (fileSizeByte / 1024).toFixed(1) + 'KB';
            } else if (fileSizeByte === 1048576) {
                fileSizeMsg = '1MB';
            } else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824) {
                fileSizeMsg = (fileSizeByte / (1024 * 1024)).toFixed(1) + 'MB';
            } else if (fileSizeByte > 1048576 && fileSizeByte === 1073741824) {
                fileSizeMsg = '1GB';
            } else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776) {
                fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(1) + 'GB';
            } else {
                fileSizeMsg = '文件超过1TB';
            }
            return fileSizeMsg;
        },
        // 获取附件数据
        gitAnnexData() {
            const hie = this.fileHierarchy;
            // const url = hie && hie[0] || this.currData ? 'api/GetFileList/Flow/' : 'api/GetAllFileList/Flow/';
            const url = 'api/GetFileList/';
            let par = hie && hie[0] ? `?directory_id=${hie[hie.length - 1].id}` : '';
            if (this.currData) {
                par = `?directory_id=${this.currData.id}`;
                this.seeFile(this.currData);
            }
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + url + this.formId + par)
                .then(res => {
                    this.fileData = res;
                    // if (this.currData) {
                    //     this.fileHierarchy.push(this.currData);
                    // }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 根据id过滤附件数据
        initFileData(newVal, val, type) {
            const initFileData = [];
            if (newVal && newVal !== null) {
                newVal.forEach(item => {
                    if (item.ParentID === val) {
                        if (type === 'FileID' && item.FileType !== '1') {
                            initFileData.push(item.FileID);
                        } else {
                            initFileData.push(item);
                        }

                    }
                });
            }
            return initFileData;
        },

        // 获取附件数据
        gieFileData(fileId, url) {
            const _that = this;
            this.loading = true;
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + url)
                .then(res => {
                    _that.fileData = res;
                })
                .catch(error => {
                    _that.fileData = [];
                    _that.$message.error(error.ErrorCode.Message);
                });
            this.loading = false;
        },
        //   重命名
        editFileClick(index) {
            const fileItem = this.fileData[index];
            const nameIndex = fileItem.file_name.lastIndexOf('.');
            fileItem.file_name = fileItem.file_name.substr(0, nameIndex);
            this.$refs.content[index].contentEditable = true;
            this.$refs.content[index].focus();
        },
        //   失焦确定重命名
        preservationName(event, index, item) {
            const newName = event.target.innerText + '.' + item.file_ext;
            this.$refs.content[index].contentEditable = false;
            const _that = this;
            this.$axios
                .put(`${this.FILE.FILE_BASE_URL_OSS()}api/FileRename/${item.id}?name=${newName}`)
                .then(res => {
                    if (res) {
                        item.file_name = newName;
                        this.$message({
                            message: '更改成功！',
                            type: 'success',
                        });
                    }
                })
                .catch(error => {
                    item.file_name = item.file_name + '.' + item.file_ext;
                    _that.$message.error(error.ErrorCode.Message);
                });
        },
        //   上传
        uploadClick(data) {
            this.currentFile = data.id;
            this.parentid = data.id;
            this.currData = data;
        },
        // 文件下载
        download(data) {
            if (data.preview_url) {
                const date = new Date();
                const endDate = new Date(data.expire);
                if (date.getTime() < endDate.getTime()) {
                    window.location.href = data.download_url;
                } else {
                    this.getFileDetailFun(data, 1);
                }
            } else {
                this.getFileDetailFun(data, 1);
            }
        },
        // 预览
        async viewFileClick(data) {
            if (data.preview_url) {
                const date = new Date();
                const endDate = new Date(data.expire);
                if (date.getTime() < endDate.getTime()) {
                    if (data.mime_type.indexOf('image/') === -1) {
                        window.open(data.preview_url, '_blank');
                    } else {
                        this.imgUrl = data.preview_url;
                        this.dialogVisible = true;
                    }
                } else {
                    this.getFileDetailFun(data, 2);
                }
            } else {
                this.getFileDetailFun(data, 2);
            }
        },
        // 获取文件下载预览地址
        getFileDetailFun(data, type) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + data.id)
                .then(res => {
                    this.fileData.forEach(item => {
                        if (item.id === data.id) {
                            item.expire = res.expire;
                            item.preview_url = res.preview_url;
                            item.download_url = res.download_url;
                        }
                    });
                    if (type === 2) {
                        if (res.mime_type.indexOf('image/') === -1) {
                            window.open(res.preview_url, '_blank');
                        } else {
                            this.imgUrl = res.preview_url;
                            this.dialogVisible = true;
                        }
                        return;
                    }
                    window.location.href = res.download_url;

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        //   删除
        deleteFileClick(index, item) {
            const _that = this;
            this.$axios
                .delete(this.FILE.FILE_BASE_URL_OSS() + 'api/DeleteFileInfo/' + item.id, {
                })
                .then(() => {
                    _that.$message({
                        message: '删除成功！',
                        type: 'success',
                    });
                    _that.gitAnnexData(this.fileID);
                })
                .catch(error => {
                    _that.$message.error(error.ErrorCode.Message);
                });
        },
        //   添加
        AddFile() {
            this.isAdd = true;
        },
        //   确认添加
        confirmAdd() {
            if (this.newFile.group_name !== '') {
                const filename = this.newFile.group_name;
                const aliyunOssToken = this.$refs.uploading.aliyunOssToken;
                const date = new Date();
                const pUrl = this.fileHierarchy && this.fileHierarchy[0] ? this.fileHierarchy[this.fileHierarchy.length - 1].path : '';
                // 判断是否过期，过期重新调用获取通信证方法
                const formData = new FormData();
                // 注意formData里append添加的键的大小写
                formData.append('key', pUrl ? `${pUrl}/${filename}` : `${aliyunOssToken.dir}${this.businessIId}/${filename}`); // 存储在oss的文件路径
                formData.append('OSSAccessKeyId', aliyunOssToken.accessid); // accessKeyId
                formData.append('policy', aliyunOssToken.policy); // policy
                formData.append('Signature', aliyunOssToken.signature); // 签名
                // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                formData.append('file', filename);
                // formData.append('FileDescription', this.newFile.FileDescription);
                formData.append('success_action_status', 200); // 成功后返回的操作码
                formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码

                // 回调参数
                const obj = {
                    bucket: aliyunOssToken.bucket ? aliyunOssToken.bucket : this.buCode, // 存储桶名称
                    business_type_code: this.buCode, // 业务类型编码
                    object_name: pUrl ? `${pUrl}/${filename}` : `${aliyunOssToken.dir}${this.businessIId}/${filename}`, // 文件对象名
                    show_name: filename, // 显示文件名
                    size: 0, // 文件大小
                    mimeType: '',
                    data_id: this.formId, // 业务数据id
                    directory_id: this.parentid ? this.parentid : '', // 目录id
                    tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                    // FileDescription: this.newFile.FileDescription, // 文件描述
                };

                if (aliyunOssToken && aliyunOssToken.expire && aliyunOssToken.expire * 1000 > date.getTime()) {
                    this.$nextTick(() => {
                        this.$refs.uploading.upload(formData, obj);
                        this.newFile.group_name = '';
                    });
                } else {
                    this.getOssToken();
                    this.$nextTick(() => {
                        this.$refs.uploading.upload(formData, obj);
                        this.newFile.group_name = '';
                    });
                }
                this.isAdd = false;
            } else {
                this.$message('请填写文件名称！');
            }
        },
        // 取消添加
        cancelAdd() {
            this.newFile.group_name = '';
            this.isAdd = false;
        },
        // 查看
        seeFile(data) {
            if (data.file_ext === '') {
                this.gieFileData(data.id, `api/GetFileList/${data.data_id}?directory_id=${data.id}`);
                this.currentFile = data.id;
                this.currData = data;
                this.fileID = data.id;
                this.parentid = data.id;
                this.fileHierarchy.push({
                    data: data.show_name,
                    ...data,
                });
            }
        },
        // 返回顶层
        backTop() {
            this.fileID = this.formId;
            this.parentid = '';
            this.currentFile = this.formId;
            this.currData = null;
            this.gieFileData(this.formId, `api/GetFileList/${this.formId}`);
            this.fileHierarchy = [];
        },
        // 返回上级目录
        returnDirectory(id, index, item) {
            this.currentFile = item.id;
            this.currData = item;
            this.fileID = item.id;
            this.parentid = item.id;
            this.gieFileData(id, `api/GetFileList/${item.data_id}?directory_id=${id}`);
            this.fileHierarchy.splice(index + 1, this.fileHierarchy.length - index);
        },
        // 上传前对文件大小进行校验
        beforeUpload(file) {
            const maxFileSize = 1024 * 1024 * 100;
            const isLt2M = file.size / 1024 / 1024;
            if (isLt2M > maxFileSize) {
                // alert(param.file.size);
                // this.readFileMD5(param);
                // this.readChunkMD5(param);
            }
        },
        // 上传文件
        // uploadFileMethod(param) {
        //     if (this.currentFile === 0) {
        //         this.currentFile = this.formId;
        //     }
        //     const formData = new FormData();
        //     formData.append('files', param.file);
        //     formData.append('code', 'Flow');
        //     formData.append('data_id', this.formId);
        //     formData.append('group_id', this.currentFile);
        //     const config = {
        //         onUploadProgress: progressEvent => {
        //             this.progressPercent = 0;
        //             this.isProgress = true;
        //             // progressEvent.loaded:已上传文件大小
        //             // progressEvent.total:被上传文件的总大小
        //             this.progressPercent = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2));
        //         },
        //     };
        //     this.$axios
        //         .post(this.FILE.FILE_BASE_URL() + 'file/uploadfile', formData, config)
        //         .then(() => {
        //             this.gitAnnexData(this.fileID);
        //             this.videoFlag = false;
        //             this.isProgress = false;
        //         }) .catch(error => {
        //             this.$message.error(error.ErrorCode.Message);
        //         });
        // },
        // readFileMD5(files) {
        //     // 读取每个文件的md5
        //     files.map((file, index) => {
        //         const fileRederInstance = new FileReader();
        //         fileRederInstance.readAsBinaryString(file);
        //         fileRederInstance.addEventListener('load', e => {
        //             const fileBolb = e.target.result;
        //             const fileMD5 = md5(fileBolb);
        //             if (!fileList.some(arr => arr.md5 === fileMD5)) {
        //                 fileList.push({ md5: fileMD5, name: file.name, file });
        //                 AllFileSize = AllFileSize + file.size;
        //             }
        //             if (index === files.length - 1) {readChunkMD5(fileList);}
        //         }, false);
        //     });
        // },
        // readChunkMD5(fileList) {
        //     // fileList.map((currentFile, fileIndex) => {
        //     const chunkSize = 1 * 1024 * 1024; // 5MB一片
        //     const chunkCount = Math.ceil(fileList.file.size / chunkSize); // 总片数
        //     // AllChunk = AllChunk + chunkCount; // 计算全局chunk数
        //     // const fileSize = fileList.file.size; // 文件大小
        //     // 针对单个文件进行chunk上传
        //     for (let i = 0; i < chunkCount; i++) {
        //         const { chunk } = getChunkInfo(fileList.file, i, chunkSize);
        //         const chunkFR = new FileReader();
        //         chunkFR.readAsBinaryString(chunk);
        //         chunkFR.addEventListener('load', e => {
        //             const chunkBolb = e.target.result;
        //             const chunkMD5 = md5(chunkBolb);
        //             this.readingFile = false;
        //             uploadChunk(currentFile, { chunkMD5, chunk, currentChunk: i, chunkCount }, fileIndex);
        //         }, false);
        //     }
        //     // });
        // },
    },
};
</script>

<style lang="stylus" scoped>
#annexData
    .Annex
        height calc(100% - 0.55rem)
        overflow-x hidden
        overflow-y auto
.img_url{
    width: 100%;
    height: 100%;
}
</style>
