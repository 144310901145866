<template>
    <div class="upload_box">
        <el-upload
            action=""
            :http-request="getfile"
            class="upload_up"
            multiple
            :show-file-list="false"
        >
            <el-button
                class="iconfont upload_btn iconfont iconshangchuan1"
                size="small"
                type="primary"
            ></el-button>
        </el-upload>
    </div>
</template>

<script>
export default {
    name: 'uploading',
    data() {
        return {
            aliyunOssToken: '',
            file: '',
            filename: '',
            fileUrl: '',
        };
    },
    props: {
        // 数据id
        businessid: {
            type: [Array, Object, String, Number ],
        },
        // key
        buCode: {
            type: [Array, Object, String, Number ],
        },
        // 父id
        parentid: {
            type: [Array, Object, String, Number ],
        },
        // 操作项
        currData: {
            type: [Array, Object, String, Number ],
        },
        // 菜单路径数据
        fileHierarchy: {
            type: [Array, Object, String, Number ],
        },
    },
    watch: {
        buCode() {
            localStorage.removeItem('aliyunOssToken');
            this.getOssToken();
        },
    },
    mounted() {
        const aliy = localStorage.getItem('aliyunOssToken') ? JSON.parse(localStorage.getItem('aliyunOssToken')) : null;
        const date = new Date();
        if (aliy && aliy.expire && aliy.expire * 1000 > date.getTime()) {
            this.aliyunOssToken = aliy;
        } else {
            this.getOssToken();
        }
    },
    methods: {
        // 获取上传通行证
        getOssToken() {
            this.$axios.get(this.FILE.FILE_BASE_URL_OSS() + 'credential/GetPostPolicy/' + this.buCode).then(res => {
                if (res) {
                    this.aliyunOssToken = res;
                    localStorage.setItem('aliyunOssToken', JSON.stringify(res));
                }
            });
        },
        // 获取文件
        getfile(e) {
            let aliy = localStorage.getItem('aliyunOssToken') ? JSON.parse(localStorage.getItem('aliyunOssToken')) : null;
            const date = new Date();
            let xOssContentType = '';
            // 防止txt 浏览器预览乱码
            if (e.file.type.includes('text/plain')) {
                xOssContentType = 'text/plain;charset=utf-8';
            }
            if (aliy && aliy.expire && aliy.expire * 1000 > date.getTime()) {
                this.$nextTick(() => {
                    this.file = e.file;
                    this.filename = this.file.name;
                    // 判断是否过期，过期重新调用获取通信证方法
                    const formData = new FormData();
                    let pUrl = this.currData ? `${this.currData.path}/` : `${aliy.dir}${this.businessid}/`;
                    if (this.fileHierarchy && this.fileHierarchy[0]) {
                        this.fileHierarchy.forEach(item => {
                            if (item.data) {
                                pUrl += `${item.data}/`;
                            }
                        });
                    }
                    // 注意formData里append添加的键的大小写
                    formData.append('key', pUrl + e.file.name); // 存储在oss的文件路径
                    formData.append('OSSAccessKeyId', aliy.accessid); // accessKeyId
                    formData.append('policy', aliy.policy); // policy
                    formData.append('Signature', aliy.signature); // 签名
                    // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                    formData.append('success_action_status', 200); // 成功后返回的操作码
                    formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码
                    if (xOssContentType) {
                        formData.append('x-oss-content-type', 'text/plain;charset=utf-8');
                    }
                    formData.append('file', this.file);

                    // 图片地址
                    this.fileUrl = aliy.host + '/' + aliy.dir + e.file.name;
                    this.upload(formData, null, e);
                });
            } else {
                this.getOssToken();
                this.$nextTick(() => {
                    aliy = localStorage.getItem('aliyunOssToken') ? JSON.parse(localStorage.getItem('aliyunOssToken')) : null;
                    this.file = e.file;
                    this.filename = this.file.name;
                    // 判断是否过期，过期重新调用获取通信证方法
                    const formData = new FormData();
                    let pUrl = this.currData ? `${this.currData.path}/` : `${aliy.dir}${this.businessid}/`;
                    if (this.fileHierarchy && this.fileHierarchy[0]) {
                        this.fileHierarchy.forEach(item => {
                            if (item.data) {
                                pUrl += `${item.data}/`;
                            }
                        });
                    }
                    // 注意formData里append添加的键的大小写
                    formData.append('key', pUrl + e.file.name); // 存储在oss的文件路径
                    formData.append('OSSAccessKeyId', aliy.accessid); // accessKeyId
                    formData.append('policy', aliy.policy); // policy
                    formData.append('Signature', aliy.signature); // 签名
                    // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                    formData.append('success_action_status', 200); // 成功后返回的操作码
                    formData.append('Content-Type', 'multipart/form-data'); // 成功后返回的操作码
                    if (xOssContentType) {
                        formData.append('x-oss-content-type', 'text/plain;charset=utf-8');
                    }
                    formData.append('file', e.file);
                    // 图片地址
                    this.fileUrl = aliy.host + '/' + aliy.dir + e.file.name;
                    this.upload(formData, null, e);
                });
            }
        },
        // 上传文件
        upload(formData, val, e) {
            let pUrl = this.currData ? `${this.currData.path}/` : `${this.aliyunOssToken.dir}${this.businessid}/`;
            if (this.fileHierarchy && this.fileHierarchy[0]) {
                this.fileHierarchy.forEach(item => {
                    if (item.data) {
                        pUrl += `${item.data}/`;
                    }
                });
            }
            $.ajax({
                url: this.aliyunOssToken.host,
                type: 'POST',
                data: formData,
                cache: false, // 不缓存数据
                processData: false, // 不处理数据
                contentType: false, // 不设置内容类型
                headers: {
                    'x-oss-content-type': 'text/plain;charset=utf-8',
                },
                success: () => { // 成功回调
                    // window.location.href = this.aliyunOssToken.host + this.aliyunOssToken.dir + `${this.businessid}/` + this.filename;
                    const file = val ? '' : e.file;
                    const obj = val ? val : {
                        bucket: this.aliyunOssToken.bucket ? this.aliyunOssToken.bucket : this.buCode, // 存储桶名称
                        business_type_code: this.buCode, // 业务类型编码
                        object_name: pUrl + e.file.name, // 文件对象名
                        show_name: e.file.name, // 显示文件名
                        size: file.size, // 文件大小
                        mimeType: file.type,
                        mime_type: file.type,
                        data_id: this.businessid, // 业务数据id
                        directory_id: this.parentid ? this.parentid : '', // 目录id
                        tenant_id: this.$takeTokenParameters('TenantId'), // 租户id
                    };
                    this.callBackFun(obj, val, e);
                },
            });
        },
        // 上传的回调
        callBackFun(obj, val, e) {
            const num = val ? 1 : 2;
            const file = e ? e.file : null;
            this.$axios.post(this.FILE.FILE_BASE_URL_OSS() + 'api/UploadCallback', obj).then(res => {
                this.$emit('uploadFirm', res.id, file, num);
            });
        },
    },
};
</script>

<style scoped lang="stylus">
    .upload_box{
        width: 50px;
        margin: 0 0 0 -22px;
        font-size: 14px;
        font-weight: 800;
    }
    .upload_up{}
    .upload_btn {
        color: #000;
        font-size: 14px;
    }
</style>
